.mainContent{
  margin-top:10px;
  margin:auto;
  min-height: 80vh;
  width:1000px;
  padding-bottom:15px;
}


a {
  color: #333;
  outline: none;
  padding-left: 3px;
  padding-right: 3px;
  text-decoration: underline;
}